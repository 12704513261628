import { useApolloClient } from '@apollo/react-hooks'
import PortalLearningPath from '@classes/LearningPath'
import PortalLearningPaths from '@classes/LearningPaths'
import MyBerlitzLessons from '@classes/Lessons'
import PortalStudentProfile from '@classes/StudentProfile'
import { LMSLearningPlans } from '@interfaces/LearningPath'
import QueryResult from '@interfaces/QueryResult'
import { ScheduleSession } from '@interfaces/ScheduleSession'
import { StoreProps } from '@interfaces/StoreState'
import { ProgramStudent, RegistrationMaterial, StudentProfileInfo } from '@interfaces/Student'
import { GET_LEARNING_PATH_BY_LPIDS } from '@queries/learningPath'
import { GET_SCHEDULES_PER_PROGRAM } from '@queries/schedules'
import { GET_SIMPLE_STUDENT_PROFILE } from '@queries/userProfile'
import { MY_BERLITZ_ENV, PROFILE_TYPE, PROGRAM_TYPES } from '@utils/constants'
import { isGodMode } from '@utils/helpers'
import { getProgramChildren } from '@utils/helpers/programs'
import learningPath from '@utils/stubdata/learningPath.json'
import { setUserFlow } from '@utils/userflow'
import React, { useEffect, useState } from 'react'
import { Query } from 'react-apollo'
import { useSelector } from 'react-redux'

export interface QueryPageResults {
  loading: boolean
  data: LMSLearningPlans[]
  error?: Error
  StudentProfile?: PortalStudentProfile
  sessions?: ScheduleSession[]
}

interface Props {
  id?: string
  /**
   * core - will only query the core LPID and related CT
   *
   * all - will both query core and elective however this will depend on the LPModuleFlag
   *  - if LPModuleFlag is off, then it will query both core and elective
   *  - if LPModuleFlag is on, then it will only query the core
   *
   * core-elective - will both query core and elective
   * (for now lets not support getting related CT but eventually we gonna need to support it)
   */
  level?: 'all' | 'core' | 'core-elective'
  type?: string
  StudentProfile?: PortalStudentProfile
  children?: any
  lpid?: string
}

const STUB = false

const formatParentForBoD1 = (
  student: StudentProfileInfo,
  path: PortalLearningPath,
  childPrograms: ProgramStudent[]
) => {
  const ProgramType = childPrograms?.[0]?.ProgramStudentInfo?.ProgramType
  const regWrappers = student?.RegistrationWrappers.map((reg) => {
    let newReg = reg
    if (reg?.RegistrationInfo?.RegistrationId === path?.Registration?.RegistrationId) {
      const Programs = reg.RegistrationInfo?.Programs.map((prog) => {
        let newProg = prog
        if (prog?.ProgramId === path?.Program?.ProgramId) {
          newProg = {
            ...prog,
            ProgramType,
          }
        }
        return newProg
      })

      newReg = {
        ...reg,
        RegistrationInfo: {
          ...reg.RegistrationInfo,
          ProgramType,
          Programs,
        },
      }
    }

    return newReg
  })

  return regWrappers
}

const MainQuery: React.FC<Props> = ({ children, type, StudentProfile, level, lpid }) => {
  const client = useApolloClient()
  const [sessions, setSessions] = useState<ScheduleSession[]>([])
  const [isSchedLoading, setSchedLoading] = useState(true)
  const [lpError, setLPError] = useState<Error | undefined>()

  const { selectedLP, isAdmin, userPermissions, isStudent } = useSelector(
    ({ dashboard, session, permissions, userProfile }: StoreProps) => ({
      selectedLP: dashboard.activeMaterial || undefined,
      isAdmin: session.isAdmin,
      userPermissions: permissions.permissions,
      isStudent: userProfile?.info?.Type === PROFILE_TYPE.STUDENT,
    })
  )

  const LPModuleFlag = userPermissions['flag::LP-modules']?.show
  const activeLPID = selectedLP && StudentProfile?.Materials?.[selectedLP] ? selectedLP : lpid

  const getLPIDS = (id?: string) => {
    /**
     * Find the registration material of the id
     */
    let match = StudentProfile?.Materials?.[id!]

    if (level === 'core') {
      if (isGodMode(isAdmin) || __DEV__) {
        console.log('LPIDS', { match })
      }
      let test: RegistrationMaterial | undefined = undefined

      if (match?.IsElective) {
        // use the core material as match cause we're not showing electives in dashboard material dropdown
        match = StudentProfile?.Materials?.[match.FoundationMaterialId!] || match
      }

      /**
       * If match is test then we don't need to query for the core
       * otherwise we need filter IsTest and get the first item
       */
      if (match?.IsTest) {
        test = match?.FromRegistration?.Materials?.[0]
      } else {
        test = match?.FromRegistration?.Materials?.filter(({ IsTest }) => IsTest)?.[0]
      }

      return [match, test].filter(Boolean)
    }

    if (level === 'core-elective') {
      /**
       * Find the elective material based on match's elective material id
       */
      let elective = StudentProfile?.Materials?.[match?.ElectiveMaterial?.LPID ?? 20]

      /**
       * If the match is elective, then lets re-assigned match to elective
       * and then we need to get the core material
       */
      if (match?.IsElective) {
        elective = match
        match = Object.keys(StudentProfile?.Materials || {}).reduce((acc, key) => {
          if (StudentProfile?.Materials?.[key]?.ElectiveMaterial?.LPID === elective?.LPID) {
            acc = StudentProfile?.Materials?.[key]
          }
          return acc
        }, undefined as any)
      }

      /**
       * If match is test then we don't need to query for elective
       */
      if (match?.IsTest) {
        elective = undefined
      }
      if (isGodMode(isAdmin) || __DEV__) {
        console.log('LPIDS', { match, elective })
      }

      return [match, elective].filter(Boolean)
    }

    let additionalMaterial: RegistrationMaterial | undefined = undefined

    // when module flag is off, prgoress bar for flex course with electives is calculated by core + elective units
    // so we need to fetch both materials

    if (!LPModuleFlag) {
      if (match?.IsElective) {
        additionalMaterial = StudentProfile?.Materials?.[match.FoundationMaterialId!]
      } else if (match?.ElectiveMaterial) {
        // when matching material has an elective
        additionalMaterial = StudentProfile?.Materials?.[match.ElectiveMaterial.LPID]
      }
    }

    if (isGodMode(isAdmin) || __DEV__) {
      console.log('LPIDS', { match, additionalMaterial })
    }
    return [match, additionalMaterial].filter(Boolean)
  }

  useEffect(() => {
    ;(async () => {
      const fetchSchedules = async () => {
        const fetchSchedulesPromise = StudentProfile?.PortalPrograms?.FlexPrograms?.map(async (Program) => {
          return await client.query({
            query: GET_SCHEDULES_PER_PROGRAM,
            variables: {
              Id: StudentProfile?.SFId,
              FromDate: Program.ProgramEnrollDate,
              EndDate: Program.getSessionSearchEndDate(StudentProfile?.Timezone),
              ProgramIds: Program.ProgramId,
            },
          })
        })

        // @ts-ignore
        return Promise.all(fetchSchedulesPromise)
      }

      const scheds = await fetchSchedules()
      setSessions(
        Array.prototype.concat.apply(
          [],
          // @ts-ignore
          scheds.map((sched) => sched.data?.getSchedulesId)
        )
      )
      setSchedLoading(false)
    })()
  }, [])

  if (!StudentProfile?.SFId) {
    return (
      <>
        {
          // @ts-ignore
          children({
            loading: false,
            data: learningPath.data.getLearningPath,
            error: new Error('GraphQL error: Invalid parameter value detected. Required parameter: username'),
            StudentProfile,
            sessions,
            LearningPaths: new PortalLearningPaths([], StudentProfile, new MyBerlitzLessons(sessions)),
          })
        }
      </>
    )
  }

  if (!selectedLP && !lpid && !isSchedLoading) {
    return (
      <>
        {
          // @ts-ignore
          children({
            loading: false,
            data: learningPath.data.getLearningPath,
            error: new Error('GraphQL error: Invalid parameter value detected. Required parameter: lpids'),
            StudentProfile,
            sessions,
            LearningPaths: new PortalLearningPaths([], StudentProfile, new MyBerlitzLessons(sessions)),
          })
        }
      </>
    )
  }

  return (
    <Query
      query={GET_LEARNING_PATH_BY_LPIDS}
      variables={{
        StudentId: StudentProfile?.SFId,
        IdType: type,
        Ids: getLPIDS(activeLPID)
          .map((lp) => lp?.LPID)
          .join('|'),
        berlitzEnv: StudentProfile?.MyBerlitzEnv ?? MY_BERLITZ_ENV.DEFAULT,
      }}
      onError={(err) => setLPError(err)}
      onCompleted={(data) => {
        /**
         * centralize way to send attributes to userflow
         *
         */
        const lp = data?.getLearningPathByIds
        if (lp && StudentProfile && activeLPID) {
          const myBerlitzLessons = new MyBerlitzLessons(sessions)
          const LearningPaths = new PortalLearningPaths(data?.getLearningPathByIds, StudentProfile, myBerlitzLessons)

          const path = LearningPaths.getLearningPathById(activeLPID)
          const childPrograms = getProgramChildren(
            path?.Program?.ProgramId ?? '',
            path?.StudentProfile?.student?.ProgramStudentWrappers ?? []
          )

          /**
           * If path has child programs and it is Berlitz on Demand 1:1
           * then we need to use that program and send it to userflow
           * otherwise we use the path
           */
          let lp = path
          if (
            path?.learningPath &&
            childPrograms?.length &&
            [...PROGRAM_TYPES.ON_DEMAND_1].includes(childPrograms?.[0]?.ProgramStudentInfo?.ProgramType) &&
            activeLPID
          ) {
            const student = {
              ...StudentProfile?.student,
              RegistrationWrappers: formatParentForBoD1(StudentProfile?.student, path, childPrograms),
            }

            const profile = new PortalStudentProfile(student)
            const LearningPaths = new PortalLearningPaths([path.learningPath], profile, myBerlitzLessons)

            lp = LearningPaths.getLearningPathById(activeLPID)
          }
          setUserFlow(StudentProfile?.SFId, isStudent, lp)
        }
      }}
    >
      {({ loading, data, error }: QueryResult) => {
        // @ts-ignore
        return children({
          loading: loading || isSchedLoading || !StudentProfile,
          data: STUB ? learningPath.data.getLearningPath : data?.getLearningPathByIds,
          error: lpError || error,
          StudentProfile,
          sessions,
          LearningPaths: new PortalLearningPaths(
            loading ? [] : data?.getLearningPathByIds,
            StudentProfile,
            new MyBerlitzLessons(sessions)
          ),
          activeLPID: activeLPID,
        })
      }}
    </Query>
  )
}

export const QueryLearningPathByInstructor: React.FC<Props> = ({ id, level, children }) => (
  <Query
    query={GET_SIMPLE_STUDENT_PROFILE}
    variables={{
      id,
    }}
    fetchPolicy="no-cache"
  >
    {(res: QueryResult) => {
      if (res.loading || res.error) {
        // @ts-ignore
        return children({
          loading: res.loading,
          error: res.error,
        })
      }

      const StudentProfile = new PortalStudentProfile(res.data?.getUserProfile?.StudentProfile?.StudentProfileInfo)
      const lpid = Object.keys(StudentProfile?.Materials || {})[0] // we don't have initial lpid selected so we need to select the first material
      return (
        <MainQuery type={PROFILE_TYPE.INSTRUCTOR} StudentProfile={StudentProfile} level={level} lpid={lpid}>
          {children}
        </MainQuery>
      )
    }}
  </Query>
)

const QueryLearningPath: React.FC<Props> = ({ level, children }) => {
  const StudentProfile = useSelector(
    ({ userProfile }: StoreProps) => new PortalStudentProfile(userProfile?.info?.StudentProfile?.StudentProfileInfo)
  )

  return (
    <MainQuery type={PROFILE_TYPE.STUDENT} StudentProfile={StudentProfile} level={level}>
      {children}
    </MainQuery>
  )
}

export default QueryLearningPath
